// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-1-file-js": () => import("./../../../src/pages/1file.js" /* webpackChunkName: "component---src-pages-1-file-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-posts-js": () => import("./../../../src/pages/blog-posts.js" /* webpackChunkName: "component---src-pages-blog-posts-js" */),
  "component---src-pages-cloudkeyz-js": () => import("./../../../src/pages/cloudkeyz.js" /* webpackChunkName: "component---src-pages-cloudkeyz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-motivetica-js": () => import("./../../../src/pages/motivetica.js" /* webpackChunkName: "component---src-pages-motivetica-js" */),
  "component---src-pages-planeta-kino-js": () => import("./../../../src/pages/planeta-kino.js" /* webpackChunkName: "component---src-pages-planeta-kino-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-publbox-js": () => import("./../../../src/pages/publbox.js" /* webpackChunkName: "component---src-pages-publbox-js" */),
  "component---src-pages-single-post-js": () => import("./../../../src/pages/single-post.js" /* webpackChunkName: "component---src-pages-single-post-js" */),
  "component---src-pages-viasat-js": () => import("./../../../src/pages/viasat.js" /* webpackChunkName: "component---src-pages-viasat-js" */),
  "component---src-pages-vichy-js": () => import("./../../../src/pages/vichy.js" /* webpackChunkName: "component---src-pages-vichy-js" */)
}

