import * as React from "react"
import { MOBILE_RESOLUTION } from "../constants/constants"

const defaultState = {
  isMobile: false,
}

const ResolutionContext = React.createContext(defaultState)


class ResolutionProvider extends React.Component<{}, {
  isMobile: boolean
}> {

  state = {
    isMobile: false,
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.checkIsMobile)
    this.checkIsMobile()
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.checkIsMobile)
  }

  checkIsMobile = () => {
    if (typeof window === 'undefined') return null;

    const isMobile = window.innerWidth <= MOBILE_RESOLUTION;
    this.setState({ isMobile })
  }

  render() {
    const { children } = this.props;
    const { isMobile } = this.state;
    return (
      <ResolutionContext.Provider
        value={{ isMobile }}
      >
        {children}
      </ResolutionContext.Provider>
    )
  }
}

export default ResolutionContext

export { ResolutionProvider }
