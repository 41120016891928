/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { ResolutionProvider } from "./src/context/ResolutionContext"
export const wrapRootElement = ({ element }) => (
  <ResolutionProvider>{element}</ResolutionProvider>
)
